import {
  chakra,
  Container,
  Heading,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { graphql, PageProps } from "gatsby";
import React, { FC } from "react";
import { Helmet } from "react-helmet";
import Card from "src/atoms/Card";
import ArticleInfo from "src/components/ArticleInfo";
import Layout from "src/components/Layout";
import Markdown from "src/components/Markdown";
import Picture from "src/components/Picture";
import StructuredText from "src/components/StructuredText";
import getExcerpt from "src/lib/getExcerpt";
import { ArticleType } from "src/types/ArticleType";

const Article: FC<PageProps<Gatsby.ArticleByIdQuery, any>> = ({
  data: { datoCmsArticle },
  pageContext,
}) => {
  if (pageContext.archiveData) {
    datoCmsArticle = pageContext.archiveData;
  }

  if (!datoCmsArticle) return null;

  const disclosure = useDisclosure();

  return (
    <Layout>
      <Helmet
        meta={[
          {
            name: "description",
            content: getExcerpt(datoCmsArticle.body, { length: 157 }),
          },
        ]}
      />

      <Modal {...disclosure} isCentered size="2xl">
        <ModalOverlay />

        <ModalContent overflow="hidden">
          <Picture image={datoCmsArticle.cover?.full} />

          <ModalCloseButton
            _hover={{ bg: "gray.700" }}
            bg="gray.800"
            color="white"
          />
        </ModalContent>
      </Modal>

      <Container as="section" maxW="container.lg">
        <Card size="lg">
          <ArticleInfo
            articleType={datoCmsArticle.articleType as ArticleType}
            mb={4}
            publicationDate={datoCmsArticle.publicationDate!}
          />

          <Heading marginBottom={10}>{datoCmsArticle.title}</Heading>

          {datoCmsArticle.coverFormat === "bandeau" && datoCmsArticle.cover && (
            <chakra.button mb={6} onClick={disclosure.onOpen}>
              <Picture image={datoCmsArticle.cover.bandeau} />
            </chakra.button>
          )}

          {datoCmsArticle.coverFormat === "medaillon" && datoCmsArticle.cover && (
            <chakra.button
              float="left"
              mb={6}
              mr={6}
              onClick={disclosure.onOpen}
            >
              <Picture image={datoCmsArticle.cover.medaillon} />
            </chakra.button>
          )}

          <Markdown>
            <StructuredText data={datoCmsArticle.body} />
          </Markdown>
        </Card>
      </Container>
    </Layout>
  );
};

export default Article;

export const query = graphql`
  query ArticleById($id: String) {
    # articlesJson(id: { eq: $id }) {
    #   datoCmsArticle {
    #     cover {
    #       # medaillon: gatsbyImageData(width: 285)
    #       # bandeau: gatsbyImageData(width: 1200, aspectRatio: 3)
    #     }
    #   }
    # }

    datoCmsArticle(id: { eq: $id }) {
      ...Article

      cover {
        medaillon: gatsbyImageData(width: 285)
        bandeau: gatsbyImageData(width: 1200, aspectRatio: 3)
      }
    }
  }
`;
