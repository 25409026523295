import { Text, TextProps } from "@chakra-ui/react";
import React from "react";
import { formatDate } from "src/lib/formatDate";
import { ArticleType } from "src/types/ArticleType";

const ARTICLE_TYPES = {
  publication: "Publications",
  event: "Événements",
  news: "Vie de la société",
} as const;

interface ArticleInfoProps extends TextProps {
  publicationDate: string;
  articleType: ArticleType;
}

const ArticleInfo = ({
  publicationDate,
  articleType,
  ...props
}: ArticleInfoProps) => (
  <Text {...props} variant="caption">
    <Text as="span" fontWeight="bold">
      {ARTICLE_TYPES[articleType]}
    </Text>

    <Text as="span"> | </Text>

    <Text as="span">{formatDate(publicationDate)}</Text>
  </Text>
);

export default ArticleInfo;
